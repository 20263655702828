.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #003b56;
}

section{
  padding-left:unset !important;
}

.ant-menu .ant-menu-item-selected, .anticon {
  color:#003b56;  
}

.services-larger-font-style{
  font-size:larger;
}

.overwrite-display-to-contents{
  display:contents !important;
}

.mobile-header-logo-show{
  display:block;
  padding-left: calc(20% - 0px);
}

.mobile-header-logo-hide{
  display:none;
}

.hand-cursor:hover{
  cursor:pointer;
}